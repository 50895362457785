import React, { useState, useEffect, useRef } from "react";
import { CustomSwitcher } from "../../../../components/CustomSwitcher/CustomSwitcher";
import { CustomDropdown } from "../../../../components/CustomDropdown/CustomDropdown";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import ModalOrderPlan from "../../Modals/ModalOrderPlan/ModalOrderPlan";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from "../../../../redux/actions";
import { ReactComponent as Upload } from "../../../../assets/icons/cloud-upload.svg";
import defaultIcon from "../../../../assets/icons/icon.svg";
import Draggable from "react-draggable";
import {
  deleteImageForMinimap,
  getImageForMinimap,
  getMiniMap,
  saveOriginalMinimap,
  getOriginalMinimap,
  updateMiniMap,
  updateShowMiniMap,
  uploadMinimapPhoto,
} from "../../../../crud/spaces/spaces";
import CustomSlider from "../../../../components/CustomSlider/CustomSlider";

const switchers = [
  {
    title: <></>,
    text: "Show Map",
    fieldName: "showMap",
  },
];

const MiniMap = ({
  spaceData,
  closeModule,
  setEditModalCansel,
  showcase,
  setEdited,
  edited,
  setUpdateMiniMap,
  poiList,
  poiEdit,
}) => {
  const [formData, setFormData] = useState([]);
  const zoom = useRef(null);
  const [isShowMap, setIsShowMap] = useState(!!spaceData.showMinimap);
  const [floor, setFloor] = useState({ name: "", value: "" });
  const [options, setOptions] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [uploadOld, setUploadOld] = useState(false);
  const [readyToUpload, setReadyToUpload] = useState(false);
  const [restoreData, setRestoreData] = useState(null);
  const [positionDrag, setPositionDrag] = useState({
    x: 0,
    y: 0,
  });
  const [poiMapSetting, setPoiMapSetting] = useState({
    width: 1,
    height: 1,
    posWidth: 0,
    posHeight: 0,
  });
  const [files, setFiles] = useState([]);
  const [isDeleteImg, setIsDeleteImg] = useState(false);
  const [renderMap, setRenderMap] = useState({
    poi: [],
    sweep: [],
  });
  const dispatch = useDispatch();
  const color = useSelector((state) => state.profile.color);

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  function toPixel(totalValue, percentage) {
    return (totalValue * percentage) / 100;
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      parseToProcent(floor?.name);
    }, 100);
  }, [poiMapSetting]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png", ".jpeg", ".jpg", ".svg"],
      "image/jpeg": [],
      "image/jpg": [],
      "image/svg+xml": [],
    },
    maxSize: 15128640,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              isCustom: true,
            })
          )
        );
        setIsDeleteImg(false);
        setEdited(true);
      }
    },
    onDropRejected: (file) => {
      let message = file[0].errors[0].message;
      let title = file[0].errors[0].code;
      if (title === "file-too-large") {
        // Manejar error de archivo demasiado grande
      } else {
        dispatch(showSimpleModalAction({ title: title, text: message }));
      }
    },
  });

  const handleSwitch = (e) => {
    let value = e.target.checked;
    setEdited(true);
    setIsShowMap((prev) => value);
  };
  const handleChangeFloor = async (value) => {
    setEdited(false);
    setFloor(value);
    setPositionDrag({ x: 0, y: 0 });
    getImg(value?.info?.minimapId, value?.info?.floor);
    let sett = value?.info?.setting;
    if (sett) {
      setPoiMapSetting({
        width: sett.width,
        height: sett.height,
        posWidth: sett.posWidth,
        posHeight: sett.posHeight,
      });
      setTimeout(() => {
        const { width, height } = getZoomedWidth();
        const pixelWidth = toPixel(sett.posWidth, width);
        const pixelHeight = toPixel(sett.posHeight, height);
        setPositionDrag({ x: pixelWidth, y: pixelHeight });
      }, 500);
    } else {
      setPoiMapSetting({
        width: 1,
        height: 1,
        posWidth: 0,
        posHeight: 0,
      });
      setPositionDrag({ x: 0, y: 0 });
    }
  };
  const getFllors = (miniMap) => {
    let arr = [];
    if (miniMap.length) {
      for (let i = 0; i < miniMap.length; i++) {
        arr.push({
          name: miniMap[i]?.floor,
          value: miniMap[i]?.floor,
          info: miniMap[i],
          state: miniMap[i].state,
        });
      }
    }
    return arr;
  };

  const loadData = (initial) => {
    // Llama a la función getMiniMap con el id del espacio y maneja la respuesta
    getMiniMap(spaceData.id)
      .then((res) => {
        // Almacena la respuesta en miniMap
        let miniMap = res.data;
        // Actualiza el estado formData con los datos obtenidos
        setFormData(miniMap);
        // Obtiene las opciones de los pisos a partir de miniMap
        let options = getFllors(miniMap);
        // Actualiza el estado options con las opciones obtenidas
        setOptions(options);
        // Si es la carga inicial y hay opciones disponibles
        if (initial === "initial" && options?.length > 0) {
          // Encuentra el piso actual basado en poiEdit o selecciona el primer piso
          const currentPoi = poiEdit
            ? options.find((item) => item.value === poiEdit.floor.value)
            : options[0];

          // Actualiza el estado floor con el piso actual
          setFloor(currentPoi);

          // Obtiene el id, el piso y la configuración del minimapa del piso actual
          let idForImg = currentPoi.info?.minimapId;
          let floor = currentPoi.info?.floor;
          let sett = currentPoi.info?.setting;
          // Llama a la función getImg para obtener la imagen del minimapa
          getImg(idForImg, floor, sett, miniMap);
          // Si hay configuración, actualiza el estado poiMapSetting con la configuración obtenida
          if (sett) {
            setPoiMapSetting({
              width: sett.width,
              height: sett.height,
              posWidth: sett.posWidth,
              posHeight: sett.posHeight,
            });
          } else {
            // Si no hay configuración, establece valores predeterminados
            setPoiMapSetting({
              width: 1,
              height: 1,
              posWidth: 0,
              posHeight: 0,
            });
            setPositionDrag({ x: 0, y: 0 });
          }

          // Get initial minimap images
          // try {
          //   prepareOriginalMinimaps(spaceData.id, res.data);
          // } catch (error) {

          // }
        }
      })
      .catch((err) => {
        // Maneja los errores de la solicitud
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        // Muestra un modal con el error
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        // Oculta el loader al finalizar la solicitud
        dispatch(hideLoaderAction());
      });
  };

  function getZoomedWidth() {
    const getElementZoom = zoom.current;
    const widthZoomElement = getElementZoom?.offsetWidth;
    const heightElement = getElementZoom?.offsetHeight;
    return { width: widthZoomElement, height: heightElement };
  }

  const submitForm = () => {
    if (readyToUpload && restoreData) {
      dispatch(showLoaderAction());

      // Usar los datos guardados para subir la imagen
      uploadMinimapPhoto(
        restoreData.spaceId,
        restoreData.floorValue,
        restoreData.formData
      )
        .then((res) => {
          setEdited(false);
          setReadyToUpload(false);
          setRestoreData(null); // Limpiar datos guardados

          dispatch(
            showSimpleModalAction({
              title: "Changes saved",
              text: "Original minimap restored successfully",
            })
          );

          // Recargar datos
          return loadData();
        })
        .catch((err) => {
          const errors = err?.response?.data;
          const { error, message } = errors || {};
          dispatch(
            showSimpleModalAction({
              title: error || "Error",
              text:
                message ||
                "You are already in the original version of the mini-map",
            })
          );
        })
        .finally(() => {
          dispatch(hideLoaderAction());
        });

      return; // Importante: retornar aquí para no ejecutar el resto del código
    }

    const { width, height } = getZoomedWidth();

    /// #Code to obtain original minimap image ID and URL
    // Obtener el ID y la URL de la imagen actual del piso desde formData
    const currentFloorData = formData.find(
      (item) => item.floor === floor?.name
    );
    const currentImgId = currentFloorData?.minimapId;
    const currentFloorLevel = currentFloorData?.floor;
    const currentImgUrl = currentFloorData?.image;

    // Usar una expresión regular para extraer la parte deseada de la URL
    const regex = /\.com\/(.+\.(png|jpg|svg))/;
    const match = currentImgUrl.match(regex);
    const extractedPath = match ? match[1] : null;

    /// # End of code to obtain original minimap image ID and URL

    let update = {
      ...floor?.info,
      setting: {
        width: poiMapSetting.width,
        height: poiMapSetting.height,
        posWidth: percentage(poiMapSetting.posWidth, width),
        posHeight: percentage(poiMapSetting.posHeight, height),
      },
    };
    dispatch(showLoaderAction());
    // console.log("submitForm update:", update); // Agregar este log
    // console.log("submitForm spaceData:", spaceData); // Agregar este log

    updateShowMiniMap(spaceData?.id, { showMinimap: isShowMap });
    updateMiniMap(spaceData?.id, floor?.info?.floor, update)
      .then((res) => {
        setUpdateMiniMap(true);
        setTimeout(() => {
          dispatch(showLoaderAction());
          loadData();
        }, 1000);
        if (!files[0]?.type) {
          edited
            ? dispatch(showSimpleModalAction({ title: "Changes saved" }))
            : null;
          setEdited(false);
        } else if (!(files.length === 0) && isDeleteImg) {
          edited
            ? dispatch(showSimpleModalAction({ title: "Changes saved" }))
            : null;
          setEdited(false);
          //dispatch(showSimpleModalAction({title:'Changes saved'}))
        }
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });

    //Use the current space id and floor level to get the original image of that floor

    if (files[0]?.type) {
      // uploadImg();
      uploadImgWithBkp(
        spaceData?.id,
        currentImgId,
        currentFloorLevel,
        extractedPath
      );
    }
    if (isDeleteImg) {
      let id = floor?.info?.id;
      // id ? deleteImg(id) : null;
      id
        ? deleteImgWithBkp(
          id,
          spaceData?.id,
          currentImgId,
          currentFloorLevel,
          extractedPath
        )
        : null;
    }
  };

  const getImgFloor = (floor, data) => {
    // Variable para almacenar el piso encontrado
    let findFloor;

    // Si se proporcionan datos, busca el piso en los datos
    if (data) {
      findFloor = data.find((item) => item.floor === floor);
      // console.log('Piso encontrado data:', findFloor);
    } else {
      // Si no se proporcionan datos, busca el piso en formData
      findFloor = formData.find((item) => item.floor === floor);
      // console.log('Piso encontrado formData:', findFloor);
    }

    // Si no se encuentra el piso, no hace nada
    if (!findFloor) return;

    // Crea un objeto con la URL de la imagen del piso y una bandera indicando que no es una imagen personalizada
    let result = { preview: findFloor.image, isCustom: false };

    // console.log('Restableciendo imagen del piso:', result);

    // Actualiza el estado files con la imagen del piso encontrada
    setFiles([result]);
  };

  const parseToProcent = async (floor) => {
    if (!spaceData && !spaceData.minimapSettings) return;
    const currSettingMinimap = spaceData.minimapSettings[0];
    if (!currSettingMinimap) return;
    let { sweeps } = await showcase.Model.getData();
    let sortSweeps = sweeps
      .filter((sweep) => sweep.floor + 1 === floor)
      .map((sweep) => {
        let px =
          ((sweep.position.x - currSettingMinimap.image_origin_x) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.width;
        px = px * 100 * poiMapSetting.width + "%";
        let py =
          ((sweep.position.z * -1 - currSettingMinimap.image_origin_y) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.height;
        py = py * 100 * poiMapSetting.height + "%";
        sweep.px = {
          right: px,
          bottom: py,
        };
        return sweep;
      });
    let sortPoi = poiList
      .filter((poi) => poi.floor === floor)
      .map((poi) => {
        let px =
          ((poi.x - currSettingMinimap.image_origin_x) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.width;
        px = px * 100 * poiMapSetting.width + "%";
        let py =
          ((poi.z * -1 - currSettingMinimap.image_origin_y) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.height;
        py = py * 100 * poiMapSetting.height + "%";
        poi.px = {
          right: px,
          bottom: py,
        };
        return poi;
      });
    setRenderMap({
      poi: sortPoi,
      sweep: sortSweeps,
    });
  };

  const getImg = (id, floor, setting, data) => {
    // console.log('Contenido de data:', data);

    // Convierte las coordenadas del piso a porcentajes
    parseToProcent(floor);

    // Si no hay id, intenta obtener la imagen del piso desde los datos
    if (!id) {
      // console.log('No hay id, intentando obtener la imagen del piso desde los datos');
      getImgFloor(floor, data);
      if (!setting) return;

      // Calcula las posiciones en píxeles basadas en el tamaño del elemento con zoom
      const { width, height } = getZoomedWidth();
      const pixelWidth = toPixel(setting.posWidth, width);
      const pixelHeight = toPixel(setting.posHeight, height);
      setPositionDrag({ x: pixelWidth, y: pixelHeight });
      return;
    }

    // Muestra el loader mientras se obtiene la imagen
    dispatch(showLoaderAction());

    // Llama a la función getImageForMinimap para obtener la imagen del minimapa
    getImageForMinimap(id)
      .then((res) => {
        // console.log('Imagen del minimapa obtenida:', res.data);
        // Si no se obtiene una URL de imagen, intenta obtener la imagen del piso
        if (res.data) {
          // console.log('Intentando obtener la imagen del piso');
          // console.log('Contenido de data:', data);
          // console.log('Contenido de data:', formData);
          getImgFloor(floor, data);
          return;
        }

        // Almacena la imagen obtenida en el estado files
        // let result = { preview: res.data, isCustom: true };
        // setFiles([result]);
      })
      .then(() => {
        // Calcula las posiciones en píxeles basadas en el tamaño del elemento con zoom
        setTimeout(() => {
          if (!setting) return;
          const { width, height } = getZoomedWidth();
          const pixelWidth = toPixel(setting.posWidth, width);
          const pixelHeight = toPixel(setting.posHeight, height);
          setPositionDrag({ x: pixelWidth, y: pixelHeight });
        }, 1000);
      })
      .catch((err) => {
        // Maneja los errores de la solicitud
        const errors = err?.response?.data;
        if (!errors) return;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        // Oculta el loader al finalizar la solicitud
        dispatch(hideLoaderAction());
      });
  };

  // #Deprecated delete minimap
  const deleteImg = (id) => {
    if (!id) return;
    dispatch(showLoaderAction());
    deleteImageForMinimap(id)
      .then((res) => {
        dispatch(
          showSimpleModalAction({
            title: "Changes saved",
            text: "Minimap delete successfully",
          })
        );
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };

  // #Deprecated upload minimap
  const uploadImg = () => {
    const formData = new FormData();
    formData.append("file", files[0]);
    // console.log("files[0]:", files[0]); // Agregar este log
    dispatch(showLoaderAction());
    uploadMinimapPhoto(spaceData?.id, floor?.value, formData)
      .then((res) => {
        // console.log("uploadMinimapPhoto response:", res); // Agregar este log
        setEdited(false);
        dispatch(
          showSimpleModalAction({
            title: "Changes saved",
            text: "Minimap uploaded successfully",
          })
        );
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };

  const deleteImgWithBkp = async (
    id,
    spaceId,
    currentImgId,
    currentFloorLevel,
    extractedPath
  ) => {
    try {
      // Obtener la imagen original
      const originalMinimap = await getOriginalMinimap(
        spaceId,
        currentFloorLevel
      );
      const imageUrl = `${process.env.REACT_APP_DO_ENDPOINT}/${originalMinimap.data}`;

      // Descargar y convertir la imagen a blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Crear un objeto File a partir del blob
      const file = new File(
        [blob],
        `minimap_${currentFloorLevel}.${blob.type.split("/")[1]}`,
        {
          type: blob.type,
        }
      );

      // Crear FormData y agregar el archivo
      const formData = new FormData();
      formData.append("file", file);

      // Guardar los datos necesarios para la restauración
      setRestoreData({
        formData: formData,
        spaceId: spaceData?.id,
        floorValue: floor?.value,
      });

      // Crear una URL temporal para la preview
      const blobUrl = URL.createObjectURL(blob);

      // Actualizar el estado files con el nuevo archivo y su preview
      setFiles([
        {
          ...file,
          preview: blobUrl,
          isCustom: false,
        },
      ]);
      setReadyToUpload(true);
      setEdited(true);

      // // Crear y subir el FormData
      // const formData = new FormData();
      // formData.append("file", file);
      // setReadyToUpload(true);
      // if (uploadOld) {
      //   console.log("entro en uploadOld");
      //   dispatch(showLoaderAction());
      //   // Subir la imagen restaurada
      //   await uploadMinimapPhoto(spaceData?.id, floor?.value, formData);

      //   setEdited(false);
      //   dispatch(
      //     showSimpleModalAction({
      //       title: "Changes saved",
      //       text: "Original minimap restored successfully",
      //     })
      // );
      // }
    } catch (err) {
      console.error("Error in deleteImgWithBkp:", err);
      const errors = err?.response?.data;
      const { error, message } = errors || {};
      dispatch(
        showSimpleModalAction({
          title: error || "Error",
          text:
            message ||
            "You are already in the original version of the mini-map",
        })
      );
    } finally {
      dispatch(hideLoaderAction());
    }
  };

  const formatFileData = (file) => {
    // Verifica si el archivo tiene el formato nuevo (array en preview)
    if (Array.isArray(file.preview)) {
      // Construye la URL completa usando la información del archivo
      const fullPath = file.preview[0].path;
      const blobUrl = `${process.env.REACT_APP_DO_ENDPOINT}/${fullPath}`;

      // Retorna el nuevo formato
      return {
        path: `/${fullPath}`,
        relativePath: `/${fullPath}`,
        preview: blobUrl,
        isCustom: true,
      };
    }

    // Si ya tiene el formato correcto, retorna el archivo sin cambios
    return file;
  };

  const urlToBlob = async (imageUrl) => {
    try {
      // Realizar la petición fetch a la URL de la imagen
      const response = await fetch(imageUrl);
      //Obtenemos el tipo
      const contentType = response.headers.get("content-type");
      // Convertir la respuesta a blob
      const blob = await response.blob();

      // Crear una URL del blob
      const blobUrl = URL.createObjectURL(blob);

      return {
        blob: blob,
        blobUrl: blobUrl,
        type: blob.type,
      };
    } catch (error) {
      console.error("Error al convertir la imagen a blob:", error);
      throw error;
    }
  };

  const uploadImgWithBkp = async (
    spaceId,
    currentImgId,
    currentFloorLevel,
    extractedPath
  ) => {
    // saveOriginalMinimap
    prepareOriginalMinimap(
      spaceId,
      currentImgId,
      currentFloorLevel,
      extractedPath
    ); // This is the only new code
    // #End of new code

    const formData = new FormData();
    let formattedFile = files[0];
    //si no file[0].preview no es blob, se convierte a blob
    if (!files[0].preview.includes("blob:")) {
      const newUrl = `${process.env.REACT_APP_DO_ENDPOINT}/${files[0]?.preview[0]?.path}`;
      const fullBlob = await urlToBlob(newUrl);
      console.log("fullBlob", fullBlob);
      console.log("formated blob", fullBlob.blobUrl);
      console.log("formated type", fullBlob.type);
      formattedFile = fullBlob.blob;
    }

    formData.append("file", formattedFile);
    console.log("file 0 cuando guarda", files[0]);

    // nuevo formato
    // [
    //   {
    //     preview: [
    //       {
    //         filename: "model-5EmnbsxyVCy-floor_1_5",
    //         fileType: "png",
    //         path: "zWmIs4tWJCZEJ0KTRzLcNP4OQDuPzU/model-5EmnbsxyVCy-floor_1_5.png",
    //       },
    //     ],
    //     isCustom: false,
    //   },
    // ];

    // Formato para guardar
    //     {
    //     "path": "/google-logo-0.jpg",
    //     "relativePath": "/google-logo-0.jpg",
    //     "preview": "blob:http://localhost:3001/83846137-bb91-4f57-8e7d-32a4929d742c",
    //     "isCustom": true
    // }
    dispatch(showLoaderAction());
    uploadMinimapPhoto(spaceData?.id, floor?.value, formData)
      .then((res) => {
        // console.log("uploadMinimapPhoto response:", res); // Agregar este log
        setEdited(false);
        dispatch(
          showSimpleModalAction({
            title: "Changes saved",
            text: "Minimap uploaded successfully",
          })
        );
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };


  const prepareOriginalMinimap = (

    spaceId,
    currentImgId,
    currentFloorLevel,
    extractedPath
  ) => {
    // This code will save the original minimap image to the database, only if not already saved
    // The backend endpoint is the one that will check if the image is already saved

    //Send the data to saveOriginalMinimap function in spaces.js
    saveOriginalMinimap(spaceId, currentImgId, currentFloorLevel, extractedPath)
      .then((res) => {
        // console.log("saveOriginalMinimap response:", res);
      })
      .catch((err) => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };

  const changePosition = (e, value, type) => {
    setPoiMapSetting((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleChangDraggble = (e, core) => {
    setPoiMapSetting((prev) => ({
      ...prev,
      posWidth: core.x,
      posHeight: core.y,
    }));
    setPositionDrag({
      x: core.x,
      y: core.y,
    });
  };

  const initialLoad = async () => {
    dispatch(showLoaderAction());
    await loadData("initial");
  };

  useEffect(() => {
    initialLoad();
  }, [poiEdit]);

  const isSimpleUrl = (str) => {
    return (
      typeof str === "string" && !str.includes("blob:") && !str.includes("http")
    );
  };

  const handleDeleteMinimap = (floor) => {
    console.log("entro en handelDeleteMinimap");
    // Obtener los datos del piso actual
    const currentFloorData = formData.find(
      (item) => item.floor === floor?.name
    );

    if (currentFloorData) {
      // Obtener los parámetros necesarios
      const id = floor?.info?.id;
      const currentImgUrl = currentFloorData?.image;

      // Extraer el path de la imagen usando regex
      const regex = /\.com\/(.+\.(png|jpg|svg))/;
      const match = currentImgUrl?.match(regex);
      const extractedPath = match ? match[1] : null;

      if (id) {
        deleteImgWithBkp(
          id,
          spaceData?.id,
          currentFloorData?.minimapId,
          currentFloorData?.floor,
          extractedPath
        );
      }
    }

    // setIsDeleteImg(true);
    getImgFloor(floor?.name);
    setEdited(true);
  };

  return (
    <>
      {isModal && (
        <ModalOrderPlan
          floor={floor}
          setIsModal={setIsModal}
          spaceData={spaceData}
        />
      )}
      <div className="mini-map">
        <h2 className="space-tab__title">{`${spaceData?.name || "Space"
          } - Mini Map`}</h2>
        {switchers.map((elem, index) => {
          return (
            <div className="space-tab__switcher-container" key={index}>
              <div className="space-tab__switcher-text-block">
                <div className="space-tab__switcher-title">{elem.title}</div>
                <div className="space-tab__switcher-text">{elem.text}</div>
              </div>
              <div className="space-tab__switcher">
                <CustomSwitcher checked={isShowMap} onChange={handleSwitch} />
              </div>
            </div>
          );
        })}
        <div className="mini-map__setting">
          <div className="mini-map__floors">
            <span>Choose Floor</span>
            <CustomDropdown
              placeholder="Floor"
              variant="grey"
              value={floor?.name}
              onChange={handleChangeFloor}
              options={options}
              serch={false}
            />
          </div>
          <CustomButton
            name="Order 2D Floorplan"
            disabled={!floor?.name}
            onClick={() => setIsModal(!isModal)}
          />
        </div>
        {
          <>
            <div className="space-tab__sliders_container">
              <div className="space-tab__input-name">Width</div>
              <CustomSlider
                color={color}
                min={0.1}
                max={3}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                step={0.01}
                spanMax={3}
                spanMin={0.1}
                name="width"
                onChange={changePosition}
                value={poiMapSetting.width}
              />
            </div>
            <div className="space-tab__sliders_container">
              <div className="space-tab__input-name">Height</div>
              <CustomSlider
                color={color}
                min={0.1}
                max={3}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                step={0.01}
                spanMax={3}
                spanMin={0.1}
                name="height"
                onChange={changePosition}
                value={poiMapSetting.height}
              />
            </div>
            <div className="space-tab__sliders_container">
              <div className="tools">
                <div className="tools__back">
                  {/*<button className='tools__button'
                          style={{ backgroundColor: color }}
                          onClick={()=>{
                            setEdited(true)
                            setFiles([])}}>Remove minimap</button>*/}
                  <button
                    className="tools__button"
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      handleDeleteMinimap(floor);
                    }}
                  >
                    Delete minimap
                  </button>
                </div>
              </div>
            </div>
          </>
        }
        {!files?.length ||
          (!files[0]?.isCustom && (
            <div {...getRootProps({ className: "dropzone" })}>
              <Upload className="dropzone__svg" />
              <input {...getInputProps()} />
              <p className="dropzone__title">Choose files to Upload</p>
              <p className="dropzone__subtitle">or use drag&drop</p>
            </div>
          ))}
        <div className="zoom">
          <div className="zoom-content" ref={zoom}>
            {files[0]?.preview && (
              <>
                {
                  // console.log("files[0]", files)
                }
                <img
                  className="img"
                  src={
                    files[0]?.preview?.includes("blob:")
                      ? files[0]?.preview
                      : isSimpleUrl(files[0]?.preview)
                        ? `https://${files[0]?.preview}`
                        : files[0].preview[0].path
                          ? `${process.env.REACT_APP_DO_ENDPOINT}/${files[0].preview[0].path}`
                          : `${process.env.REACT_APP_DO_ENDPOINT}/${files[0].preview[0].filename}.${files[0].preview[0].fileType}`
                  }
                  alt="mini-map img"
                />
                <Draggable
                  handle=".handle"
                  grid={[1, 1]}
                  scale={1}
                  position={positionDrag}
                  onStop={handleChangDraggble}
                >
                  <div className="draggble-img">
                    <div className="mini-map__pois handle">
                      {renderMap?.poi?.map((poi, index) => (
                        <img
                          key={index}
                          className="mini-map__poi"
                          src={poi.icon || defaultIcon}
                          style={{
                            left: `${poi.px.right}`,
                            bottom: `${poi.px.bottom}`,
                            zIndex: "10",
                          }}
                          alt="POI icon"
                        />
                      ))}
                      {renderMap?.sweep?.map((sweep, index) => (
                        <span
                          key={index}
                          className="mini-map__sweep"
                          style={{
                            left: `${sweep.px.right}`,
                            bottom: `${sweep.px.bottom}`,
                          }}
                        ></span>
                      ))}
                    </div>
                  </div>
                </Draggable>
              </>
            )}
          </div>
        </div>
        <div className="space-tab__buttons">
          <div className="space-tab__cancel-btn">
            <CustomButton
              name="Cancel"
              variant="grey"
              onClick={(e) => {
                e.preventDefault();
                if (edited) {
                  setEditModalCansel((prev) => !prev);
                } else {
                  closeModule();
                }
              }}
            />
          </div>
          <div className="space-tab__submit-btn">
            <CustomButton
              variant="green"
              name="Save"
              onClick={(e) => {
                e.preventDefault();
                submitForm();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniMap;
